import React from 'react';
import SectionImage from '../assets/jump.jpg';

function Insurance(){
  return (
    <div style={styles.section}>
      <div style={styles.image}></div>
      <div style={styles.content}>
        <h1>Insurance</h1>
        <h4>We accept most Insurances</h4>

        <ul style={{listStyle: 'none', padding: 0}}>
          <li style={{margin: '10px'}}>Horizon</li>
          <li style={{margin: '10px'}}>Aetna</li>
          <li style={{margin: '10px'}}>Medicare</li>
          <li style={{margin: '10px'}}>Cigna</li>
          <li style={{margin: '10px'}}>United Healthcare</li>
        </ul>

        <h4>We do not participate with NJ Family Care or Medicaid at this time</h4>
      </div>
    </div>
  )
}

const styles = {
  section: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  image: {
    flexGrow: 1,
    backgroundImage: `url(${SectionImage})`,
    backgroundSize: 'cover',
    width: '25em',
    height: '25em'
  },
  content: {
    flexGrow: 1,
    width: '25em',
    textAlign: 'center',
    padding: '1em'
  }
}

export default Insurance;
