import React from 'react';

function Footer(){
  return(
    <div style={{display: 'flex', justifyContent: 'space-between', padding: '0 1em 0 1em'}}>
      <h3>{'\u00A9'} Discover Chiropractic</h3>
      <h3>
        <a href="http://unitehenry.com/" style={{textDecoration: 'none', color: 'inherit'}}>
        Designed By Henry Unite
        </a>
      </h3>
    </div>
  )
}

export default Footer;
