import React from 'react';

function Address(){
  return(
    <div style={styles.section}>
      <div style={styles.map}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d98004.34313152567!2d-75.3003480613552!3d39.859995838984084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa55cd235881eed85!2sDiscover+Chiropractic+Center!5e0!3m2!1sen!2sus!4v1551831724762"
          frameBorder="0"
          style={{border: 0, width: '100%', height: '100%'}}
          title="map"
          allowFullScreen>
        </iframe>
      </div>
      <div style={styles.content}>
        <h1>Location</h1>
        <h3>69 S Black Horse Pike, Blackwood, NJ 08012</h3>
      </div>
    </div>
  )
}

const styles = {
  section: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  map: {
    flexGrow: 1,
    width: '25em'
  },
  content: {
    flexGrow: 1,
    width: '25em',
    textAlign: 'center',
    padding: '7em 1em 7em 1em'
  }
}

export default Address;
