import React, { Component } from 'react';
const firebase = require('firebase/app');
require('firebase/firestore');

const CONFIG = {
  projectId: "discover-chiropractic-b5272",
  apiKey: "AIzaSyCw-cAtBjD82B41zWCoWVpJxqXs9jJ66rE",
  authDomain: "discover-chiropractic-b5272.firebaseapp.com",
  databaseURL: "https://discover-chiropractic-b5272.firebaseio.com",
  storageBucket: "discover-chiropractic-b5272.appspot.com"
}

firebase.initializeApp(CONFIG);
const db = firebase.firestore().collection('contacts');

class Contact extends Component {

  state = {
    buttonHover: false,
    contact: {
      name: '',
      number: ''
    },
    submit: false
  }

  submit = () => {
    if(!this.state.submit && this.state.contact.number.length >= 10){
      db.add({
        name: this.state.contact.name,
        number: this.state.contact.number
      })
      this.setState({submit: true})
    }
  }

  handleChange = (e) => {
    let contact = this.state.contact;
    contact[e.target.name] = e.target.value;
    this.setState({contact: contact})
  }

  render(){
    return(
      <div style={styles.section}>
        <h1>Contact</h1>
        <h4>(856) 401-0102</h4>
        <h3>Leave your name and number and we'll get back to you</h3>
        {this.state.submit ? <h2>We will get back to you!</h2> : null}
        <div style={styles.form}>
          <input style={styles.input} type="text" placeholder="NAME" name="name" onChange={this.handleChange}/>
          <input style={styles.input} type="text" placeholder="NUMBER" name="number" onChange={this.handleChange}/>
        </div>
        <button
          style={this.state.buttonHover ? hoverStyles.button : styles.button}
          onMouseDown={() => this.setState({buttonHover: true})}
          onMouseUp={() => this.setState({buttonHover: false})}
          onClick={this.submit}
        >
          Submit
        </button>
      </div>
    )
  }

}

const hoverStyles = {
  button: {
    border: 'none',
    fontSize: '1em',
    padding: '1em',
    width: '10em',
    outline: 'none',
    backgroundColor: '#fff',
    color: '#5C5C60',
    fontFamily: 'inherit',
    cursor: 'pointer'
  }
}

const styles = {
  section: {
    textAlign: 'center',
    padding: '1em',
    borderTop: '1px solid #5C5C60',
    borderBottom: '1px solid #5C5C60',
    marginTop: '1em'
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  input: {
    outline: 'none',
    flexGrow: 1,
    fontSize: '3em',
    margin: '15px',
    padding: '5px',
    width: '6em',
    border: 'none',
    fontFamily: 'inherit'
  },
  button: {
    border: 'none',
    fontSize: '1em',
    padding: '1em',
    width: '10em',
    outline: 'none',
    backgroundColor: '#5C5C60',
    color: '#fff',
    fontFamily: 'inherit',
    cursor: 'pointer'
  }
}

export default Contact;
