import React from 'react';

function Hours(){
  return (
    <div style={{paddingTop: '1em'}}>
      <h1 style={styles.header}>Hours</h1>
      <div style={styles.section}>
        <div style={{fontSize: '1.1em'}}>
          <h2>Dr. Mike</h2>
          <ul style={{listStyle: 'none', padding: '1em'}}>
            <li style={{margin: '15px'}}>Monday: 9AM - 12:30PM | 2PM - 7PM</li>
            <li style={{margin: '15px'}}>Tuesday: 2PM - 6PM</li>
            <li style={{margin: '15px'}}>Wednesday: 9AM - 12:30PM | 2PM - 7PM</li>
            <li style={{margin: '15px'}}>Thursday: Off</li>
            <li style={{margin: '15px'}}>Friday: 9AM - 12:30PM | 2PM - 6PM</li>
            <li style={{margin: '15px'}}>Saturday: 10AM - 1PM</li>
          </ul>
        </div>

        <div style={{fontSize: '1.1em'}}>
          <h2>Dr. Doe</h2>
          <ul style={{listStyle: 'none', padding: '1em'}}>
            <li style={{margin: '15px'}}>Monday: 9AM - 12PM | 3PM - 6PM</li>
            <li style={{margin: '15px'}}>Tuesday: 9AM - 12PM | 3PM - 5PM</li>
            <li style={{margin: '15px'}}>Wednesday: Off</li>
            <li style={{margin: '15px'}}>Thursday: 10:30AM - 1:30PM</li>
            <li style={{margin: '15px'}}>Friday: 9AM - 12PM</li>
            <li style={{margin: '15px'}}>Saturday: 8AM - 10AM</li>
          </ul>
        </div>
      </div>
      <h2 style={{textAlign: 'center', marginBottom: '2em'}}>We are closed on Sundays</h2>
    </div>
  )
}

const styles = {
  section: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  header: {
    textTransform: 'uppercase',
    textAlign: 'center'
  }
}

export default Hours;
