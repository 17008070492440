import React, { Component } from 'react';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import './Images.css';

import ImageOne from '../assets/images/ImageOne.jpg';
import ImageTwo from '../assets/images/ImageTwo.jpg';
import ImageThree from '../assets/images/ImageThree.jpg';
import ImageFour from '../assets/images/ImageFour.jpg';
import ImageFive from '../assets/images/ImageFive.jpg';
import ImageSix from '../assets/images/ImageSix.jpg';
import ImageSeven from '../assets/images/ImageSeven.jpg';
import ImageEight from '../assets/images/ImageEight.jpg';
import ImageNine from '../assets/images/ImageNine.jpg';

const IMAGES = [
  ImageOne,
  ImageTwo,
  ImageThree,
  ImageFour,
  ImageFive,
  ImageSix,
  ImageSeven,
  ImageEight,
  ImageNine
]

class Images extends Component{

  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render(){
    const { photoIndex, isOpen } = this.state;

    return(
      <div style={styles.section}>
        {
          IMAGES.map((image, imageIndex) => {
            return (
              <img
                className='Image'
                style={styles.image}
                src={image}
                alt=""
                key={image}
                onClick={() => this.setState({isOpen: true, photoIndex: imageIndex})}
              />
            )
          })
        }

        {isOpen && (
          <Lightbox
            mainSrc={IMAGES[photoIndex]}
            nextSrc={IMAGES[(photoIndex + 1) % IMAGES.length]}
            prevSrc={IMAGES[(photoIndex + IMAGES.length - 1) % IMAGES.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + IMAGES.length - 1) % IMAGES.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % IMAGES.length,
              })
            }
          />
        )}
      </div>
    )
  }
}

const styles = {
  section: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: '1em',
    borderBottom: '1px solid #5C5C60'
  },
  image: {
    height: '20em',
    width: '20em',
    objectFit: 'cover',
    margin: '5px',
    cursor: 'pointer'
  }
}

export default Images;
