import React, { Component } from 'react';
import './App.css';

import Banner from './components/Banner';
import Hours from './components/Hours';
import Insurance from './components/Insurance';
import Prices from './components/Prices';
import Contact from './components/Contact';
import Address from './components/Address';
import Images from './components/Images';
import Footer from './components/Footer';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Banner />
        <Hours />
        <Insurance />
        <Prices />
        <Address />
        <Contact />
        <Images />
        <Footer />
      </div>
    );
  }
}

export default App;
