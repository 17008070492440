import React from 'react';
import SectionImage from '../assets/climb.jpg';

function Prices(){
  return(
    <div style={styles.section}>
      <div style={styles.content}>
        <h1>Call to make an Appointment!</h1>
      </div>
      <div style={styles.image}></div>
    </div>
  )
}

/* Pricing
<h1>Pricing</h1>
<h3>Without Insurance</h3>
<ul style={{listStyle: 'none', padding: 0}}>
  <li style={{margin: '10px'}}>New Patient Exam: $95</li>
  <li style={{margin: '10px'}}>Established Patient Exam: $40</li>
  <li style={{margin: '10px'}}>Re-Exam: $50</li>
</ul>
*/

const styles = {
  section: {
    display: 'flex',
    flexWrap: 'wrap-reverse',
  },
  content: {
    flexGrow: 1,
    textAlign: 'center',
    width: '25em',
    padding: '10em 1em 5em 1em',
  },
  image: {
    backgroundImage: `url(${SectionImage})`,
    backgroundSize: 'cover',
    backgroundPosition: '0% 80%',
    flexGrow: 1,
    width: '25em',
    height: '25em'
  }
}

export default Prices;
