import React from 'react';
import BannerImage from '../assets/banner.jpg';

function Banner(){
  return (
    <div style={styles.banner}>
      <h1 style={styles.header}>Discover Chiropractic</h1>
      <h4 style={styles.subHeader}>Family, Sports, and Wellness Chiropractic Care</h4>
    </div>
  )
}

const styles = {
  banner: {
    backgroundImage: `url(${BannerImage})`,
    backgroundSize: 'cover',
    backgroundPosition: '0% 60%',
    backgroundAttachment: 'fixed',
    color: '#F1F5F2',
    padding: '10em 1em 10em 1em',
    textShadow: '.5px .5px #5C5C60',
    fontSize: '115%'
  },
  header: {
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  subHeader: {
    textAlign: 'center'
  }
}

export default Banner;
